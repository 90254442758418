<template data-server-rendered="true">
    <div id="app">
        <section class="hero is-primary  is-bold is-fullheight">
            <video id="heroVideo" poster="./assets/images/videoposter.jpg" playsinline  autoplay muted loop src="./assets/images/Highlights.mp4" type="video/mp4"></video>
            <div class="hero-head">
                <nav class="is-desktop navbar">
                    <div class="container">
                        <div class="navbar-brand">
                            <a class="navbar-item center-logo">
                                <img src="./assets/images/logo.svg" width="120" alt="Curiolis Inc. logo">
                            </a>

                        </div>

                        <div class="navbar-menu">
                            <div class="navbar-end">
                                <div class="tabs is-right is-family-secondary">
                                    <ul>
                                        <li><a href="#about">About</a></li>
                                        <li><a href="#services">Services</a></li>
                                        <li><a href="#work">Work</a></li>
                                        <li><a href="#contact">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title">
                        Helping clients develop<br>user-centered solutions
                    </h1>
                    <a href="#contact" class="button is-large is-outlined is-white extra-top-margin" >Get in touch</a>
                </div>
            </div>
        </section>

        <div class="section">
        <div class="container main-body">
            <div class="container columns has-extra-vertical-padding" id="about">
                <div class="column is-1">
                    <h2 class="has-text-centered-mobile is-size-4 is-family-secondary is-info has-text-link has-text-primary">About</h2>
                </div>

                <div class="intro column is-8 is-offset-1">
                    <p class="is-size-4">Curiolis is a small design and human factors consultancy focusing on user-centered
                        product development: the art and science of crafting user experiences that meet users’ needs and
                        exceed their expectations.
                    </p>
                </div>
            </div>
        </div>
        </div>
        <div class="section profile-background">
            <div class="container">
                <div class="container columns has-extra-vertical-padding">

                    <div class="column is-6 is-offset-2 has-text-centered">
                        <p class="has-text-left is-size-4">Curiolis is run by Jonathan Kendler, a leading user interface
                            designer and human factors engineer specializing in healthcare-related products.</p>
                        <a
                        href="http://www.linkedin.com/in/jonathan-kendler" target="_blank"
                                class="button linkedin-button is-family-secondary is-primary is-outlined is-medium has-text-centered">
                            View LinkedIn profile
                        </a>
                    </div>

                    <div class="column is-2">
                        <img src="./assets/images/jk.png" alt="Jonathan Kendler - President of Curiolis Inc." class="profile-photo">
                    </div>
                </div>
            </div>
        </div>

        <div class="section has-background-primary">
            <div class="container" id="services">
                <div class="container columns has-text-white">
                    <div class="column is-1">
                        <h2 class="has-text-centered-mobile is-size-4 is-family-secondary is-info has-text-link">Services</h2>
                    </div>
                    <div class="column is-10">
                        <h2 class="is-size-4 has-text-centered">Curiolis' primary services include:</h2>
                    </div>
                </div>
                <div class="container columns has-text-white service-summary">
                    <div class="column is-2 has-text-centered is-animated is-offset-2">
                        <div class="service-icon uidesign"></div>
                        <h4 class="is-size-4">User interface design</h4>
                        <ul>
                            <li>Conceptual design</li>
                            <li>Detailed design</li>
                            <li>Visual design</li>
                        </ul>
                    </div>
                  <div class="column is-2 has-text-centered is-animated is-offset-1">
                    <div class="service-icon usability"></div>
                    <h4 class="is-size-4">Usability engineering</h4>
                    <ul>
                      <li>Expert reviews</li>
                      <li>Use-related risk analysis</li>
                      <li>Program planning</li>

                    </ul>
                  </div>
                  <div class="column is-2 has-text-centered is-animated is-offset-1">
                        <div class="service-icon swdev"></div>
                        <h4 class="is-size-4">Software development</h4>
                        <ul>
                            <li>Interactive prototyping</li>
                            <li>Front-end development</li>
                            <li>MVP development</li>
                        </ul>
                    </div>

                </div>
                <div class="container columns has-text-white ">
                    <div class="column is-10 is-offset-1 ">
                        <h2 class="is-size-4 has-text-centered">Looking for additional services? <a href="#contact"
                                                                                                            class="contact-link">Get
                            in touch</a></h2>
                    </div>
                </div>
            </div>

        </div>

        <div class="section work-section" id="work">
            <div class="container">
            <div class="container columns has-extra-vertical-padding">
                <div class="column is-1">
                    <h2 class="has-text-centered-mobile is-size-4 is-family-secondary is-info has-text-primary">Work</h2>
                </div>
                <div class="intro column is-8 is-offset-1">
                    <p class="is-size-4 work-first-paragraph">Jonathan has over 20 years of experience working on a wide range of medical devices including dialysis machines, patient monitors, infusion pumps, and glucose monitors. His human factors engineering and user interface design work has helped various medical device manufacturers gain regulatory approval for their products, as well as gain users’ praise. He has also worked on various consumer products, industrial instruments, and software applications.
                    </p>

                    <img src="./assets/images/portfolio.png" class="is-hidden-touch portfolio-photo" alt="Jonathan Kendler's product design portfolio includes dialysis machines, patient monitors, infusion pumps, and various other medical devices, consumer products, and software applications.">
                    <img src="./assets/images/portfolio_mobile.png" class="is-hidden-desktop portfolio-photo" alt="Jonathan Kendler's product design portfolio includes dialysis machines, patient monitors, infusion pumps, and various other medical devices, consumer products, and software applications.">

                    <p class="is-size-4">In addition to his consulting work, Jonathan has co-authored numerous articles on user interface design and usability engineering, as well as two books (<a href="https://www.crcpress.com/Usability-Testing-of-Medical-Devices-Second-Edition/Wiklund-PE-Kendler-Strochlic/p/book/9781466595880" target="_blank"><em>Usability Testing of Medical Devices</em></a> and
                        <a href="https://www.crcpress.com/Designing-for-Safe-Use-100-Principles-for-Making-Products-Safer/Wiklund-Kendler-Tilliss-Costantino-Ansems-Ng-Post-Geel-Aronchick-Dorfman/p/book/9781138299177" target="_blank"><em>Designing for Safe Use</em></a>). He has contributed to several industry guidelines and standards, including
                        <a href="https://www.iso.org/standard/63179.html" target="_blank">IEC 62366 </a>(<em>Application of usability engineering to medical devices</em>),
                        <a href="https://my.aami.org/store/detail.aspx?id=HE752018PDF">AAMI HE75</a> (<em>Human factors engineering - Design of medical devices</em>), and
                        <a href="http://dx.doi.org/10.6028/NIST.GCR.15-996" target="_blank">NIST GCR 15-996</a> (<em>Technical Basis for User Interface Design of Health IT</em>).
                    </p>
                    <img src="./assets/images/books.png" class="is-hidden-touch book-photo" alt="Jonathan Kendler has co-written two books: Usability Testing of Medical Devices and Designing for Safe Use">
                    <img src="./assets/images/books_mobile.png" class="is-hidden-desktop book-photo" alt="Jonathan Kendler has co-written two books: Usability Testing of Medical Devices and Designing for Safe Use">

                    <p></p>
                </div>
            </div>
            </div>
        </div>

        <div class="section training-background">
            <div class="container">
                <div class="container columns has-extra-vertical-padding">

                    <div class="column is-6 is-offset-2 has-text-centered">
                        <p class="has-text-left is-size-4">Jonathan has also co-taught user interface design courses at Tufts University and has delivered workshops and lectures on user interface design and usability engineering throughout Asia, Europe, and North America.


                        </p>
                    </div>


                    <div class="column is-3">
                        <img src="./assets/images/JKTraining.jpg" alt="Jonathan Kendler delivering a workshop on human factors engineering" class="training-photo">
                    </div>
                </div>
            </div>
        </div>

        <div class="section has-background-primary" id="contact">
            <div class="container">
                <form  method="post"
                       @submit.prevent="handleSubmit">
                    <input type="hidden" name="form-name" value="ask-question" />
                    <div class="container columns has-text-white">
                    <div class="column is-1">
                        <h2 class="has-text-centered-mobile is-size-4 is-family-secondary is-info has-text-link">Contact</h2>
                    </div>
                    <div class="column is-10 is-offset-1">
                        <h2 class="is-size-4">Looking for support or just want to ask a question? Get in touch...</h2>
                    </div>
                </div>
                    <div class="container columns has-text-white">
                        <div class="column is-4 is-offset-2">
                            <input class="custom-input" type="text" name="name" @input="ev => form.name = ev.target.value" placeholder="Your name"/>
                        </div>
                        <div class="column is-4">
                            <input class="custom-input" type="text" name="company" @input="ev => form.company = ev.target.value" placeholder="Your company"/>
                        </div>
                </div>
                    <div class="container columns has-text-white">
                        <div class="column is-4 is-offset-2">
                            <input class="custom-input" type="text" name="email" @input="ev => form.email = ev.target.value" placeholder="Your email address"/>
                        </div>
                        <div class="column is-4">
                            <input class="custom-input" type="text" name="phone" @input="ev => form.phone = ev.target.value" placeholder="Your phone number"/>
                        </div>
                    </div>

                    <div class="container columns has-text-white">
                        <div class="column is-8 is-offset-2">
                            <textarea class="custom-input" type="text" name="message" @input="ev => form.message = ev.target.value" placeholder="Your message"/>
                        </div>

                    </div>

                    <div class="container columns has-text-white">
                        <div class="column is-8 is-offset-2">
                            <b-checkbox type="is-white" v-model="acceptedPrivacyPolicy">
                                By submitting this message I accept Curiolis' Privacy Policy
                            </b-checkbox>
                        </div>

                    </div>

                    <div class="container columns has-text-white">
                        <div class="column is-2 is-offset-2">
                            <button type="submit" class="button has-text-primary is-family-secondary is-medium has-text-centered">Send message</button>
                        </div>
                    </div>

                </form>
            </div>

        </div>

        <b-modal :active.sync="showingContactError" >
            <div class="card is-rounded is-centered">
                <div class="card-content is-rounded is-centered">
                    <p class="is-size-4 has-text-centered" v-if="this.form.name=='' || this.form.email==''">Please enter your <span v-if="this.form.name ==''">name</span> <span v-if="this.numMissingFields > 1">and <br></span><span v-if="this.form.email==''">email address.</span></p>
                    <p class="is-size-4 has-text-centered" v-if="!acceptedPrivacyPolicy && this.form.name && this.form.email">You must accept Curiolis' Privacy Policy before sending a message.</p>
                    <div class="button is-centered ok-button" @click="showingContactError = false">OK</div>
                </div>

            </div>
        </b-modal>

        <b-modal :active.sync="showingMessageSent" >
            <div class="card is-rounded is-centered">
                <div class="card-content is-rounded is-centered">
                    <p class="is-size-4 has-text-centered">Thank you for contacting us! We'll be in touch shortly. </p>
                    <div class="button is-centered ok-button" @click="showingMessageSent = false">OK</div>
                </div>

            </div>
        </b-modal>

        <b-modal :active.sync="showPrivacyPolicy" >
            <div class="card legal-text" >
                <div class="card-content">
                <h1 class="is-size-3 is-bold">Curiolis Inc. Privacy Policy</h1>
                <p>It is Curiolis, Inc.'s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="https://www.curiolis.com">https://www.curiolis.com</a> (hereinafter, "us", "we", or "https://www.curiolis.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
                <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>

                <h2>Website Visitors</h2>
                <p>Like most website operators, Curiolis, Inc. collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Curiolis, Inc.'s purpose in collecting non-personally identifying information is to better understand how Curiolis, Inc.'s visitors use its website. From time to time, Curiolis, Inc. may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>

                <h2>Gathering of Personally-Identifying Information</h2>
                <p>Certain visitors to Curiolis, Inc.'s websites choose to interact with Curiolis, Inc. in ways that require Curiolis, Inc. to gather personally-identifying information. The amount and type of information that Curiolis, Inc. gathers depends on the nature of the interaction. For example, we ask visitors who contact us via our contact form at https://www.curiolis.com to provide their name and email address.</p>

                <h2>Security</h2>
                <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                <h2>Links To External Sites</h2>
                <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
                <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>


                <h2>Aggregated Statistics</h2>
                <p>Curiolis, Inc. may collect statistics about the behavior of visitors to its website. Curiolis, Inc. may display this information publicly or provide it to others. However, Curiolis, Inc. does not disclose your personally-identifying information.</p>


                <h2>Cookies</h2>
                <p>To enrich and perfect your online experience, Curiolis, Inc. uses "Cookies".</p>
                <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Curiolis, Inc. uses cookies to help Curiolis, Inc. identify and track visitors, their usage of https://www.curiolis.com, and their website access preferences. Curiolis, Inc. visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Curiolis, Inc.'s websites, with the drawback that certain features of Curiolis, Inc.'s websites may not function properly without the aid of cookies.</p>
                <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Curiolis, Inc.'s use of cookies.</p>



                <h2>Privacy Policy Changes</h2>
                <p>Although most changes are likely to be minor, Curiolis, Inc. may change its Privacy Policy from time to time, and in Curiolis, Inc.'s sole discretion. Curiolis, Inc. encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
                </div>
            </div>

        </b-modal>

        <b-modal :active.sync="showTermsOfUse">
            <div class="card legal-text" >
                <div class="card-content">
                    <h1 class="is-size-3">Curiolis, Inc. Terms of Use</h1>
                    <h3>1. Terms</h3>
                    <p>By accessing the website at <a href="http://www.curiolis.com">http://www.curiolis.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
                    <h3>2. Use License</h3>
                    <ol type="a">
                        <li>Permission is granted to temporarily download one copy of the materials (information or software) on Curiolis, Inc.'s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                            <ol type="i">
                                <li>modify or copy the materials;</li>
                                <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                <li>attempt to decompile or reverse engineer any software contained on Curiolis, Inc.'s website;</li>
                                <li>remove any copyright or other proprietary notations from the materials; or</li>
                                <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                            </ol>
                        </li>
                        <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Curiolis, Inc. at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
                    </ol>
                    <h3>3. Disclaimer</h3>
                    <ol type="a">
                        <li>The materials on Curiolis, Inc.'s website are provided on an 'as is' basis. Curiolis, Inc. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                        <li>Further, Curiolis, Inc. does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
                    </ol>
                    <h3>4. Limitations</h3>
                    <p>In no event shall Curiolis, Inc. or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Curiolis, Inc.'s website, even if Curiolis, Inc. or a Curiolis, Inc. authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                    <h3>5. Accuracy of materials</h3>
                    <p>The materials appearing on Curiolis, Inc.'s website could include technical, typographical, or photographic errors. Curiolis, Inc. does not warrant that any of the materials on its website are accurate, complete or current. Curiolis, Inc. may make changes to the materials contained on its website at any time without notice. However Curiolis, Inc. does not make any commitment to update the materials.</p>
                    <h3>6. Links</h3>
                    <p>Curiolis, Inc. has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Curiolis, Inc. of the site. Use of any such linked website is at the user's own risk.</p>
                    <h3>7. Modifications</h3>
                    <p>Curiolis, Inc. may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
                    <h3>8. Governing Law</h3>
                    <p>These terms and conditions are governed by and construed in accordance with the laws of Massachusetts and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

                </div>
            </div>

        </b-modal>


        <div class="section has-background-primary custom-footer">
            <div class="container">
                
                    <div class="container columns has-text-white">
                        <div class="column is-1">
                            <h2 class="has-text-centered-mobile is-size-4 is-family-secondary is-info has-text-link">
                                <img src="./assets/images/logo.svg" alt="Curiolis Inc. logo">
                                
                            </h2>
                        </div>
                        <div class="column is-10 has-text-centered extra-top-padding">
                            <span class="is-size-6 is-centered footer-link" @click="showPrivacyPolicy = true">Privacy Policy</span>
                            <span class="is-size-6 is-centered footer-link" @click="showTermsOfUse = true">Terms of Use</span>
                            <span class="is-size-6 is-centered copyright">&copy; Curiolis, Inc. {{currentYear}}</span>
                        </div>
                    </div>

               
            </div>

        </div>


    </div>
</template>

<script>

    export default {
        data() {
            return {
                form: {
                    name: "",
                    email: "",
                    phone:"",
                    company:"",
                    message:"",

                },
                acceptedPrivacyPolicy: false,
                showingContactError: false,
                showingMessageSent: false,
                showPrivacyPolicy: false,
                showTermsOfUse: false,
                sent: false,
                status: {}

            }
        },

        computed: {
            currentYear: function () {
                let currDate = new Date();
                return currDate.getFullYear()
            },

            numMissingFields: function(){
                let numFields = 0;
                if(this.form.name ===""){
                    numFields++;
                }
                if(this.form.email ===""){
                    numFields++;
                }
                return numFields;
            }
        },

        methods: {
            encode(data) {
                return Object.keys(data)
                    .map(
                        key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                    )
                    .join("&");
            },
            handleSubmit() {
                if(this.form.name === "" || this.form.email === "" || !this.acceptedPrivacyPolicy ){
                    this.showingContactError = true;
                } else {
                    fetch("/", {
                        method: "POST",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: this.encode({
                            "form-name": "ask-question",
                            ...this.form
                        })
                    })
                        .then(() => {
                            this.showingMessageSent = true;
                            //this.$router.push("thanks");
                        })
                        .catch(() => {

                            //this.$router.push("404");
                        });
                }
                }


        }
    }

</script>

<style lang="scss">
    @import './assets/style.scss';

    #app {
    }

    #heroVideo {
        position: absolute;
        right: 0;
        top: 0;
        min-width: 100vw;
        height: 100vh;
        object-fit: cover;
        opacity: .5;
    }

    h1.title {
        font-size: 3.5rem;
        font-weight: 100;
        line-height: 95%;
        margin-bottom: 5rem;
    }

    .hero-body {
        padding-top: 6.5rem !important;
        padding-bottom: 6.5rem !important;
    }

    .hero-head {
        padding-top: 35px;
    }

    .main-body {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }

    .has-extra-vertical-padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .stretched-image {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 200vw;
        max-height: 100px;
        filter: blur(200);
        object-fit: cover;
        z-index: -10;
    }

    .profile-photo {
        border-radius: 6px;
        margin-top: -58px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        //max-width: 120%;
    }

    .book-photo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin-top: 2rem;
    }

    .portfolio-photo {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        margin-bottom:2rem;
        display:block;
    }

    .ok-button {
        margin-left: auto;
        margin-right: auto;
        display:block;
        margin-top: 2rem;
        width: 50%;
    }

    .training-photo {
        border-radius: 6px;
        margin-top: -58px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .profile-background {
        background-image: url('./assets/images/blurrybackground.jpg');
        background-size: cover;
        padding-top: .7rem !important;
        padding-bottom: 5rem !important;
    }

    .footer-link {
        &:hover{
            text-decoration: underline;
        }

        cursor: pointer;
    }

    .legal-text{

        padding: 2rem;

        h1{
            font-family: $family-secondary;
            color: $primary;
        }
        h2, h3{
            font-family: $family-secondary;
            font-size: 1.2rem;
            font-weight: bold;
            color: $primary;
        }
        ol{
            margin-left: 1.5rem;
            margin-bottom: 1.8rem;
        }

        p{
            margin-bottom: 1.8rem;
        }
    }

    .checkbox:hover {
        color: white !important;
    }

    .training-background {
        background-image: url('./assets/images/blurrybackground2.jpg');
        background-size: cover;
        padding-top: .7rem !important;
        padding-bottom: 1.5rem !important;
    }

    .service-summary {

        margin-top: 2rem;
        margin-bottom: 2rem;

        h4 {
            font-weight: bold;
            line-height: .9;
            border-bottom: 2px dotted white;
            padding-bottom: 1rem;
        }

        ul {
            padding-top: 1rem;
            margin-bottom: 1.5rem;
        }

        .service-icon {
            display: block;
            height: 140px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 2rem;
            margin-left: auto;
            margin-right: auto;
            background-position: center;
        }

        .uidesign {
            background-image: url('./assets/images/icon_uidesign.svg')
        }

        .swdev {
            background-image: url('./assets/images/icon_dev.svg')
        }

        .usability {
            background-image: url('./assets/images/icon_usability.svg')
        }

    }

    .linkedin-button {
        margin-top: 1.5rem;
    }

    .contact-link {
        color: #fffea1;

        &:hover {
            color: #fffc00;
            text-decoration: underline;
        }
    }


    .work-first-paragraph{
        //background: rgb(255,255,255);
        //background: linear-gradient(180deg, rgba(255,255,255,0.9164040616246498) 32%, rgba(255,255,255,0) 100%);
        //border-radius: 6px;
        //padding: 3rem;
        //margin: -3rem;
        //margin-bottom: 1rem;
        //box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .work-section{
        background-image: url('./assets/images/work_background.jpg');
        background-repeat: no-repeat;
        background-size: contain;

    }

    .custom-input{
        -webkit-appearance: none;
        border: none;
        border-bottom: 1px dotted #7b96ae;
        background: none;
        width: 100%;
        padding: .5rem;
        font-size: 1.5rem;
        color: white;
        transition: all .2s ease;

        &::placeholder {
            color: #7b96ae;
            font-style: italic;
        }

        &:hover {
            background: #003c72;
        }

        &:focus{
            outline: none;
            background: #004480;
            border-radius: 3px;
            border-bottom: 1px dotted white;
        }
    }

    .send-button {
        background-color: white;

    }

    .custom-footer {
        opacity: .9;
        border-top: 1px dotted #7b96ae;

        span{
            margin-left: 3rem;
        }
    }

    .copyright {
        margin-left: 3rem;
    }

    .extra-top-padding{
        padding-top: 1rem;
    }

    .extra-top-margin {
        margin-top: 2rem;
    }

    #services{
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    #work {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    #contact {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    a {
        color: #008de2;
    }

    .tabs li{
        letter-spacing: .05rem;
    }

    html {
        scroll-behavior: initial;
    }

    @media all and (max-width: 768px) {
        h1.title{
            font-size: 3.3rem;
        }

        .center-logo {
            margin-left: auto;
            margin-right: auto;
        }

        .main-body {
            margin-top: 0rem;
            margin-bottom: 0rem;
        }

        .has-extra-vertical-padding {
            padding-top: 2rem;
            margin-top: -4rem;
            padding-bottom: 0rem;
        }

        .linkedin-button {
            margin-bottom: 5rem;
        }

        .profile-background {
            padding-bottom: 1.5rem !important;
        }

        #services {
                padding-top: 0rem !important;
                padding-bottom: 0rem !important;
                margin-top: -2rem;
        }

        #work {
            padding-top: 3rem;
        }

        .portfolio-photo {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0rem;
            width: 100%;
            display: block;
        }

        .book-photo {
            width: 100%;
            margin-bottom: -3rem;
        }

        .training-photo{
            margin-top: 0rem;
        }

        #contact {
            padding-top: 1.5rem;
        }

    }

</style>
